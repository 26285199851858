export const educationData = [
    {
        id: 1,
        institution: 'IES Francisco Romero Vargas, Cádiz',
        course: 'CFGS - Desarrollo de Aplicaciones Web',
        startYear: '2014',
        endYear: '2017'
    },
    {
        id: 2,
        institution: 'IES Asta Regia, Cádiz',
        course: 'ESO y Bachillerato de Ciencias',
        startYear: '2008',
        endYear: '2014'
    }
]