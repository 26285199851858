export const experienceData = [
    {
        id: 1,
        company: 'Xerintel Internet Technologies SL',
        jobtitle: 'Desarrollador web y aplicaciones móviles híbridas',
        startYear: 'Septiembre 2017',
        endYear: 'Presente'
    },
    {
        id: 2,
        company: 'Trendix - Creative Apps',
        jobtitle: 'Desarrollador web',
        startYear: 'Marzo 2017',
        endYear: 'Junio 2017'
    },
]